<template>
  <div class="devnote">
    <slot />
  </div>
</template>

<style lang="scss">
  .devnote {
    border-radius: $border-radius;
    background: $yellow-tint;
    padding: $space-s;
    border: 2px solid $yellow-shape;
  }
</style>
